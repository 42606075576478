.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/* InformeMaterial.js */
.inputDropDownMaterial{
  width: 300px;
}
.idDropdownSelectMaterial .text{
  color: rgba(0,0,0,.87);
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 4px;
}

/* Incidencias.jsx */
/* Filter fecha */
.container-filter-date{
  border: 1px solid rgba(34,36,38,.15);
  box-sizing: border-box;
  padding: 9.5px 12px;
}
.container-filter-date:hover{
  border: 1px solid rgba(34,36,38,.35);
}
.item-filter-date{
  background-color: transparent;
	border: none;
  box-sizing: border-box;
  cursor: pointer;
	display: block;
  padding: 20px 0 20px 5px;
  text-align: start;
  width: 100%;
}

/* YCSSelectDate.jsx */
.identificator-datepicker-select-date .react-datepicker-wrapper,
.identificator-datepicker-select-date .react-datepicker__input-container,
.identificator-datepicker-select-date .ui.input
{
  width: 100%;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Responsives */
@media screen and (max-width: 1220px) {
  .inputDropDownMaterial{
    width: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .inputDropDownMaterial{
    width: 150px;
  }
}
@media screen and (max-width: 900px) {
  .inputDropDownMaterial{
    width: 100px;
  }
}
