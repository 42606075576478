@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.textotabla {
	font-size: 12px;
}

.derecha{
  float: right;
}

input[type=file] {
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
  width: 0px;
  height: 0px;
}

.search .results {
  overflow-y: auto;
  max-height: 300px;
  }